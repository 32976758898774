 
.BBPBodyView {
    flex: 1 1;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
}

.pt-0 {
    padding-top: 0;
}

.BBPBodyView .BBPBVInner {
    height: 100%;
    display: flex;
    flex-direction: row;
}
.BBPMLogo {
    display: flex;
    align-items: center;
}

.BBPMLogo a {
    display: flex;
}

.BBPMLogo a img {
    width: 120px;
}
.BBPUserDropdown {
    display: flex;
    align-items: center;
    max-width: 200px;
    overflow: hidden;
    margin-left: 40px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.BBPUserDropdown .BBPUDImg {
    display: flex;
}

.BBPUserDropdown .BBPUDName {
    padding: 0 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #131523;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}

.BBPUserDropdown .BBPUDIcon {
    display: flex;
    align-items: center;
    color: #175783;
}

.BBPUDPopper {
    padding-top: 10px;
    z-index: 9999;
}

.BBPUDPopper .BBPUDMenus {
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
}

.BBPUDPopper .BBPUDMenus ul li {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.BBPMenuBar {
    background-color: #fff;
    padding: 10px 16px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px #15223214;
}

.BBPMenuBar .BBPMInner {
    display: flex;
    align-items: center;
}

.BBPMenuBar .BBPMInner .BBPMNU {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
}

.BBPMenuBar .BBPMInner .BBPMNU .BBPMNUInnner {
    display: flex;
    align-items: center;
}
.BBPLeftSidebar {
    width: 269px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPLeftSidebar .BBPLSInner {
    padding: 16px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPLeftSidebar .BBPLSInner .BBPLSIMOuter {
    position: relative;
}

.BBPLeftSidebar .BBPLSInner .BBPLSIMenu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #2a2a2a;
    padding: 12px 20px;
    position: relative;
    text-transform: capitalize;
    transition: all ease .3s;
    border-radius: 0;
    text-decoration: none;
}


.BBPLeftSidebar .BBPLSInner .BBPLSIMChild {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #2a2a2a;
    padding: 10px 20px;
    padding-left: 55px;
    position: relative;
    text-transform: capitalize;
    transition: all ease .3s;
    border-radius: 0;
    text-decoration: none;
}

.BBPLeftSidebar .BBPLSInner .BBPLSIMChild:hover,
    .BBPLeftSidebar .BBPLSInner .BBPLSIMChild.BBPLSICActive {
	color: #175783;
}

.BBPLeftSidebar .BBPLSInner .BBPLSIMenu:hover,
.BBPLeftSidebar .BBPLSInner .BBPLSIMenu.BBPLSIActive {
    background: #0062ff10;
    color: #000;
}

.BBPLeftSidebar .BBPLSInner .BBPLSIMenu:hover::before,
.BBPLeftSidebar .BBPLSInner .BBPLSIMenu.BBPLSIActive::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 2px;
    background: #175783;
    transition: all ease .3s;
}
  
.BBPLeftSidebar .BBPLSInner .BBPLSIMenu svg {
    font-size: 18px;
    margin-right: 16px;
    color: #7e84a3;
}
 
.BBPLeftSidebar .BBPLSInner .BBPLSIMOuter.BBPLSIMOC a.BBPLSIMenu ~ a.BBPLSIMChild {
    display: none;
}

.BBPLeftSidebar .BBPLSInner .BBPLSIMOuter.BBPLSIMOC a.BBPLSIMenu.BBPLSIActive ~ a.BBPLSIMChild {
    display: block;
}
.BBPLoginPage {
    width: 100%;
}

.BBPLoginPage {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.BBPLoginPage .BBPLPInfo {
    width: 50%;
    background-color: #175783;
    padding: 16px;
}
  
.BBPLoginPage .BBPLPForm {
    width: 50%;
    background-color: #fff;
    padding: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
} 

.BBPLoginPage .BBPLPForm .BBPLPFInner {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 
.BBPLoginPage .BBPLPForm .BBPLPFInner .BBPLPFITitle {
    font-size: 18px;
    font-weight: 600;
    color: #175783;
    margin-bottom: 25px;
    text-align: center;
}

.BBPLoginPage .BBPLPForm .BBPLPFInner .BBPLPFIInput {
    margin-bottom: 25px;
}

.BBPLoginPage .BBPLPForm .BBPLPFInner .BBPLPFIBottom {
    font-size: 14px;
    font-weight: 500;
    color: #000; 
    text-align: center;
}

.BBPLoginPage .BBPLPForm .BBPLPFInner .BBPLPFIBottom a {
    color: #175783;
}

.BBPLoginPage form.rjsf fieldset {
    padding: 0;
    margin: 0;
} 

.BBPLoginPage .BBPForm form.rjsf .field-object > fieldset .form-group {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
}

.BBPLoginPage .BBPForm form.rjsf .field-object > fieldset .form-group label.control-label {
    font-size: 16px;
    font-weight: 500;
    color: #175783;
    line-height: normal; 
}

.BBPLoginPage .BBPLPForm .BBPLPFInner .BBPLPFIInput .BBPLPFIIBtn {
    display: flex;
    justify-content: center;
}

.BBPLoginPage .BBPForm form.rjsf .field-object > fieldset .form-group .form-control {
    border-color: #175783;
    padding: 15px;
    border-radius: 4px;
    font-size: 14px; 
    color: #175783;
    outline: 0;
    letter-spacing: .5px;
}

.BBPLoginPage .BBPLPInfo .BBPLPIInner .BBPLPILogo {
    display: flex;
}

.BBPLoginPage .BBPLPInfo .BBPLPIInner .BBPLPILogo img {
    width: 200px;
}
 
.BBPLoginPage .BBPLPInfo .BBPLPIInner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPLoginPage .BBPLPInfo .BBPLPIInner  .BBPLPSlider {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center; 
}

.BBPLPSlider .BBPLPSSlide {
    text-align: center;
}

.BBPLPSlider .BBPLPSSlide .BBPLPSSImg {
    display: flex;
    justify-content: center;
}

.BBPLPSlider .BBPLPSSlide .BBPLPSSImg img {
    width: 400px;
    height: 400px;
    object-fit: contain;
}
 
.BBPLPSlider .BBPLPSSlide .BBPLPSSTitle {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
}

.BBPLPSlider .BBPLPSSlide .BBPLPSSSubTitle {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .5px;
}
 
.BBPLPSlider .BBPLPSSlide .BBPLPSSDots {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.BBPLPSlider .BBPLPSSlide .BBPLPSSDots .BBPLPSSDot {
    width: 15px;
    height: 15px;
    background-color: #ffffff75;
    margin: 0 7px;
    border-radius: 100%;
    cursor: pointer;
}

.BBPLPSlider .BBPLPSSlide .BBPLPSSDots .BBPLPSSDot.BBPLPSSDActive {
    background-color: #fff;
}
.SomethingWentWrong {
    padding: 20px;
}

.SomethingWentWrong .SWWImg {
    display: flex;
    justify-content: center;
}

.SomethingWentWrong .SWWImg img {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.SomethingWentWrong .SWWTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.BBPRightSidePanel {
    width: calc(100% - 269px);
    padding-left: 16px;
}

.BBPRSPFull {
    width: 100%;
    padding-left: 0;
}

.BBPRightSidePanel .BBPRSPInner {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPRightSidePanel.BBPRSPRBG .BBPRSPInner { 
    border-radius: 0;
    background-color: unset; 
    box-shadow: unset; 
} 
.BBPDashboardPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.BBPDashboardPage .BBPDPTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: normal;
    padding-bottom: 16px;
}

.BBPDashboardPage .BBPDPCharts {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 15px;
    column-gap: 15px;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.BBPAreaChart {
    width: calc(25% - 12.5px);
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    padding: 20px;
}

.BBPAreaChart .BBPACMTitle {
    font-size: 14px;
    line-height: normal;
    color: #7e84a3;
    margin-bottom: 15px;
}

.BBPAreaChart .BBPACInfo {
    display: flex;
}

.BBPAreaChart .BBPACInfo .BBPACIDet {
    width: 50%;
    align-self: flex-end;
}

.BBPAreaChart .BBPACInfo .BBPACIDet .BBPACIDTitle {
    color: #131523;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}

.BBPAreaChart .BBPACInfo .BBPACIDet  .BBPACIDSubTitle {
    display: flex;
    align-items: center;
}

.BBPAreaChart .BBPACInfo .BBPACIDet .BBPACIDSubTitle .BBPACIDPer {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: normal; 
}

.BBPAreaChart .BBPACInfo .BBPACIDet .BBPACIDSubTitle .BBPACIDPTitle {
    font-size: 12px;
    color: #7e84a3;
    line-height: normal;
    padding-left: 3px;
}

.BBPAreaChart .BBPACInfo  .BBPACIChart {
    width: 50%;
}
 
.BBPDashboardPage .BBPDPLineChart {
    margin: 15px 0;
    padding: 20px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead .BBPDPlCHTitle {
    flex: 1 1;
    font-size: 16px;
    font-weight: 600;
    color: #131523;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-cartesian-axis-tick {    
    font-size: 12px;
    font-weight: 500;
} 

.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-cartesian-axis-tick text.recharts-text { 
    fill: #7E84A3;
}
 
.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-cartesian-axis-tick line.recharts-cartesian-axis-tick-line,
.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-cartesian-axis line.recharts-cartesian-axis-line {
    display: none;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-surface .recharts-tooltip-cursor {
    stroke: #5891FF;
    transition: all ease .10s;
}
    
.BBPCustomTooltip {
    background-color: #fff;
    border: 1px solid #ededed;
    padding: 5px;
    padding-bottom: 0;
    border-radius: 4px;
}

.BBPCustomTooltip .BBPCTitle {
    font-size: 14px;
    line-height: normal;
    font-weight: 600; 
    color: #000;
    text-transform: capitalize;
}

.BBPCustomTooltip .BBPCList {
    font-size: 12px;
    line-height: normal;
    padding: 4px 0;    
    color: #7E84A3;
}

.BBPCustomTooltip .BBPCList strong {
    color: #000;
    margin-right: 7px;
    font-weight: 500;
}

.BBPCustomTooltip .BBPCList span {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    margin-right: 7px;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-wrapper ul.recharts-default-legend {
    font-size: 16px;
    line-height: normal;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-wrapper ul.recharts-default-legend li.recharts-legend-item span.recharts-legend-item-text {
    color: #5A607F!important;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-wrapper ul.recharts-default-legend li.recharts-legend-item svg.recharts-surface {
    width: 8px;
    height: 8px;
}

.BBPDashboardPage .BBPDPBarCharts {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 15px;
    column-gap: 15px;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.BBPDPBarCharts .BBPBarChart {
    width: 100%;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    padding: 20px;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead {
    display: flex;
    padding-bottom: 10px;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead .BBPBCHTitle {
    flex: 1 1;
    font-size: 16px;
    font-weight: 600;
    color: #131523;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead .BBPBCHBtn {
    display: flex;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead .BBPBCHBtn button {
    color: #5A607F;
    border-color: #D7DBEC;
    text-transform: capitalize;
    font-size: 14px;
}

.BBPDPBarCharts .BBPBarChart .BBPBCChart {
    overflow: auto;
    height: 425px;
}
 
.BBPDPBarCharts .BBPBarChart .recharts-wrapper ul.recharts-default-legend {
    font-size: 16px;
    line-height: normal;
}

.BBPDPBarCharts .BBPBarChart .recharts-wrapper ul.recharts-default-legend li.recharts-legend-item span.recharts-legend-item-text {
    color: #5A607F!important;
}

.BBPDPBarCharts .BBPBarChart .recharts-wrapper ul.recharts-default-legend li.recharts-legend-item svg.recharts-surface {
    width: 8px;
    height: 8px;
}

.BBPDPBarCharts .BBPBarChart .recharts-cartesian-axis-tick {
    font-size: 12px;
    font-weight: 500;
}

.BBPDPBarCharts .BBPBarChart .recharts-cartesian-axis-tick text.recharts-text { 
    fill: #7E84A3;
    text-transform: capitalize;
}
 
.BBPDPBarCharts .BBPBarChart .recharts-cartesian-axis-tick line.recharts-cartesian-axis-tick-line,
.BBPDPBarCharts .BBPBarChart .recharts-cartesian-axis line.recharts-cartesian-axis-line {
    display: none;
}

.BBPDPBarCharts .BBPBarChart .recharts-surface .recharts-tooltip-cursor {
    stroke: #5891FF;
    transition: all ease .10s;
    fill: transparent;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead .BBPDPlCHFilter {
    display: flex;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead .BBPDPlCHFilter button {
    line-height: normal;
    text-transform: capitalize;
    font-weight: 500;
    color: #fff;
    background-color: #175783;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}
.BBPRangePicker {
    position: relative;
}

.BBPRangePicker button.BBPRPDate { 
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    background-color: #175783;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 7px 15px;
    text-decoration: none;
    border: 2px solid #175783;
    min-width: 125px;
    justify-content: space-between;
}

.BBPRangePicker button.BBPRPReset {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    background-color: #175783;
    font-size: 20px;
    border-radius: 10px;
}

.BBPRPCal {
    z-index: 999;
    padding-top: 20px;
}

.BBPRPCal .BBPRPCOuter { 
    position: relative;
}

.BBPRPCal .BBPRPCOuter::before {
    content: '';
    position: absolute;
    top: -7px;
    right: 15px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-radius: 4px;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-radius: 4px;
    position: relative;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .rdrCalendarWrapper .rdrMonthAndYearWrapper {
    padding-top: 0;
    height: 45px;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .rdrCalendarWrapper .rdrMonths .rdrMonth {
    padding: 0 0.833em 0.833em 0.833em;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .BBPRPCIBtn {
    display: flex;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .BBPRPCIBtn button {
    flex: 1 1;
    border-radius: 0;
    outline: 0;
    border: 0;
    box-shadow: unset;
    padding: 10px 10px 7px;
    background-color: #d32f2f;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .BBPRPCIBtn button:first-child { 
    background-color: #175783;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .rdrDefinedRangesWrapper .rdrInputRanges {
    padding: 23px 0;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .BBPRPCISRBtn {
    position: absolute;
    bottom: 0;
    width: 226px;
    left: 0;
    display: flex;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .BBPRPCISRBtn button {
    flex: 1 1;
    border-radius: 0;
    outline: 0;
    border: 0;
    box-shadow: unset;
    padding: 10px 10px 7px;
    background-color: #d32f2f;
}

.BBPRPCal .BBPRPCOuter .BBPRPCInner .BBPRPCISRBtn button:first-child {
    background-color: #175783;
}
.BBPSearchMain {
    display: flex;
    align-items: center;
}

.BBPSearchBox {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 300px;
}
 
.BBPSearchBox > svg {
    position: absolute;
    font-size: 20px;
    top: 6px;
    left: 13px;
    color: #5a607f;
}

.BBPSearchBox .BBPSBClose {
    position: absolute;
    right: 6px;
    top: 8px;
    font-size: 16px;
    padding: 0px;
    z-index: 1;
    background-color: #fff;
    color: #5a607f;
}

.BBPSearchBox .BBPSBInput {
    padding: 6px 10px 6px 40px;
    margin: 0;
    border: 0;
    display: block;
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #5a607f;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    background-color: transparent;
    outline: 0;
    width: 100%;
}

.BBPSearchBox.BBPSBSStart .BBPSBInput {
    padding-right: 30px;
}

.BBPSearchMain > button {
    font-size: 22px;
    margin-left: 10px;
}
.BBPNoData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}
.BBPNoData .BBPNDInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.BBPNoData .BBPNDInner .BBPNDIImg {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
 
.BBPNoData .BBPNDInner .BBPNDIImg > img {
    width: 250px;
    height: 250px;
    object-fit: contain;
}

.BBPNoData .BBPNDInner .BBPNDITitle {
    font-size: 24px;
    line-height: normal;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.BBPNoData .BBPNDInner .BBPNDIDes {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #000;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.BBPNoData.small  .BBPNDInner .BBPNDIImg > img {
    width: 150px;
    height: 150px;
}
.BBPNoData.small .BBPNDInner .BBPNDITitle {
    font-size: 18px;
}
.BBPDevTable {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    border-radius: 6px;
    overflow: auto;
}

.BBPDevTable .BBPDTInner {
    flex: 1 1;
    overflow: auto;
    position: relative;
    background-color: #fff;
}

.BBPDevTable .BBPDTInner .BBPDTIResponsive {
    display: block;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.BBPDevTable .BBPDTInner .BBPDTIResponsive table.BBPTable {
    width: 100%;
    overflow: auto;
    border-collapse: collapse; 
}

.BBPDevTable .BBPDTInner .BBPDTIResponsive table.BBPTable thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
}

.BBPDevTable .BBPDTInner table thead tr th,
.BBPDevTable .BBPDTInner table thead tr th:first-child {
    padding: 0;
    background-color: #F5F6FA;
}

.BBPDevTable .BBPDTInner table thead tr th .CellLayout-container .Content-content {
    flex: 1 1;
}

.BBPDevTable .BBPDTInner table thead tr th .CellLayout-container .Content-content span.Title-title {
    display: block;
    width: 100%;
}

.BBPDevTable .BBPDTInner table tbody tr td {
    padding: 0;
    border-left: 1px solid #ededed;
    border-bottom: 1px solid #ededed; 
} 


.BBPDevTable .BBPDTInner table tbody tr td:first-child {
    padding: 0;
    border-left: 0;
} 

.BBPDevTable .BBPDTInner table .BBPDTHead {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #5D5D5D;
}

.BBPDevTable .BBPDTInner table .BBPDTHead.BBPDTHSort { 
    cursor: pointer;
}

.BBPDevTable .BBPDTInner .BBPDTIREmpty table .BBPDTHead.BBPDTHSort {
    pointer-events: none;
}

.BBPDevTable .BBPDTInner table .BBPDTHead .BBPDTHText {
    line-height: normal;
    font-size: 13px;
    color: #131523;
    text-align: left;
    line-height: normal;
    font-weight: normal;
    text-transform: uppercase;
    flex: 1 1;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden; 
}
 
.BBPDevTable .BBPDTInner table .BBPDTHead .BBPDTHText span {
    display: block;
}

.BBPDevTable .BBPDTInner table .BBPDTHead .BBPDTHIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    opacity: 0;
    transition: all ease .3s;
}

.BBPDevTable .BBPDTInner table .BBPDTHead.BBPDTHSort.BBPDTHSOn .BBPDTHIcon,
.BBPDevTable .BBPDTInner table .BBPDTHead.BBPDTHSort:hover .BBPDTHIcon { 
    opacity: 1;
}

.BBPDevTable .BBPDTInner table .BBPDTSText {
    padding: 10px 15px;
    color: #131523;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.BBPDevTable .BBPDTInner table .BBPDTSGroup .BBPDTSGItem {
    padding: 10px 15px;
    color: #131523;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    border-bottom: 1px solid #ddd;
}

.BBPDevTable .BBPDTInner table .BBPDTSGroup .BBPDTSGItem:last-child {
    border-bottom: 0;
}


.BBPDevTable .BBPDTInner table .BBPDTIBtns {
    padding: 5px 15px;
    color: #131523;
}

.BBPDevTable .BBPDTInner table .BBPDTIBtns .BBPDTIBIcon {
    color: #175783;
    margin: 0 5px;
    font-size: 16px;
}

.BBPDevTable .BBPDTInner table .BBPDTIBtns .BBPDTIBDes {
     opacity: .5;
}

.BBPDevTable .BBPDTInner .Layout-root,
.BBPDevTable .BBPDTInner .Layout-root .TableContainer-root,
.BBPDevTable .BBPDTInner .Layout-root .TableContainer-root > div {
    height: 100%; 
}

.BBPDevTable .BBPDTBottom {
    display: flex;
    padding: 10px 15px;
    border-top: 1px solid #e0e0e0;
}

.BBPDevTable .BBPDTBottom .BBPDTBPag {
    flex: 1 1;
}

.BBPDTPagination ul li button {
    color: #7E84A3;
    font-size: 12px;
    line-height: normal;
    min-width: 28px;
    height: 28px;
}


.BBPDTPagination ul li button:hover,
.BBPDTPagination ul li button.Mui-selected:hover,
.BBPDTPagination ul li button.Mui-selected {
    background-color: #175783;
    color: #fff;
}

.BBPDTPagination ul li button.Mui-selected {
    pointer-events: none;
}

.BBPDevTable .BBPDTInner table tbody tr td.BBPTNDCol {
    position: unset;
    border: 0;
}

.BBPTNDBox {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BBPDevTable .BBPDTBottom .BBPDTBPNum {
    font-size: 12px;
    color: #000;
    font-weight: 600;
}

.BBPDevTable .BBPDTInner table .BBPDTImgText {
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

.BBPDevTable .BBPDTInner table .BBPDTImgText .BBPDTSITImg {
    width: 35px;
    height: 35px;
    border-radius: 100%;
}

.BBPDevTable .BBPDTInner table .BBPDTImgText .BBPDTSITImg img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: contain;
    object-position: center;
}

.BBPDevTable .BBPDTInner table .BBPDTImgText .BBPDTSITText {
    flex: 1 1;
    color: #131523;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    padding-left: 12px;
}

.BBPDevTable .BBPDTInner table .BBPDTChips {
    padding: 5px 10px;
    display: flex;
    flex-wrap: wrap;
}

.BBPDevTable .BBPDTInner table .BBPDTChips .BBPDTCChip {
    font-size: 10px;
    line-height: normal; 
    border-radius: 8px;
    color: #fff;
    padding: 3px 10px;
    margin: 5px; 
    min-width: 90px;
    text-align: center;
}

.BBPDevTable .BBPDTInner table .BBPDTChips .BBPDTCChip.Failed {
    background-color: #ee2505;
} 

.BBPDevTable .BBPDTInner table .BBPDTChips .BBPDTCChip.Canceled {
    background-color: #d32f2f;
}

.BBPDevTable .BBPDTInner table .BBPDTChips .BBPDTCChip.Success,
.BBPDevTable .BBPDTInner table .BBPDTChips .BBPDTCChip.Completed {
    background-color: #2e7d32;
}

.BBPDevTable .BBPDTInner table .BBPDTChips .BBPDTCChip.Pending {
    background-color: #ed6c02;
}
.BBPDevTable .BBPDTInner table .BBPDTChips .BBPDTCChip.UnderProcess {
    background-color: #ffbf00; 
}

.BBPDevTable .BBPDTInner table  .BBPDTSImg {
    padding: 0 10px;
    display: flex;
}

.BBPDevTable .BBPDTInner table .BBPDTSImg img {
    width: 38px;
    height: 38px;
    object-fit: contain;
}

.BBPDevTable .BBPDTInner table .BBPDTSText.BBPDTSErr {
    display: flex;
    align-items: center;
    background-color: #fdeded;
    color: #ef5350;
}

.BBPDevTable .BBPDTInner table .BBPDTSText.BBPDTSWarn {
    background-color: #fff4e5;
    color: #ff9800;
}

.BBPDevTable .BBPDTInner table .BBPDTSText.BBPDTSErr .BBPDTSEText {
    flex: 1 1;
}

.BBPDevTable .BBPDTInner table .BBPDTSText.BBPDTSErr svg {
    font-size: 18px;
    color: #ef5350;
}
.PageChangeDialog {
    padding: 20px;
    text-align: center;
}

.PageChangeDialog .PCDIcon {
    display: flex;
    justify-content: center;
    font-size: 50px;
    color: #ee2505;
}

.PageChangeDialog .PCDTitle {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.PageChangeDialog .PCDDes {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    color: #434343;
}

.PageChangeDialog .PCDBtn {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.PageChangeDialog .PCDBtn button { 
    margin: 0 15px; 
} 
.BBPBackdrops {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BBPBackdrops .BBPBLoader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
  
  /* Safari */
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.BBPBackdrops .BBPBTitle {
    text-align: left;
    margin-top: 15px;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}

.BBPBackdrops .BBPBTitle span {
    margin-right: 5px;
}

.BBPBackdrops .BBPBTitle::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;      
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "..."; 
    width: 0px;
    letter-spacing: 5px;
  }
  
  @keyframes ellipsis {
    to {
      width: 1.5em;    
    }
  }
.BBPReports {
    display: flex;
    flex-direction: column;
    height: 100%;
}
 
.BBPReports .BBPRHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column-gap: 16px;
    column-gap: 16px;
} 

.BBPReports .BBPRHead .BBPRHBtn {
    display: flex;
    flex-wrap: wrap;
    padding-left: 24px;
    flex: 1 1;
    justify-content: flex-end;
}

.BBPReports .BBPRHead .BBPRHBtn .BBPRHBD {
    margin-left: 16px;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    background-color: #175783;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 7px 15px;
    text-decoration: none;
    border: 2px solid #175783;
}

.BBPReports .BBPRHead .BBPRHBtn .BBPRHBD[disabled] {
    opacity: .5;
}

.BBPReports .BBPRBody {
    flex: 1 1;
    padding-top: 16px;
    overflow: auto;
} 

/*  */


.BBPRViewModel {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPRViewModel .BBPRVMHead {
    border-bottom: 1px solid #ddd; 
    display: flex;
    flex-wrap: wrap;
}

.BBPRViewModel .BBPRVMHead .BBPRVMHTitle { 
    font-size: 16px;
    font-weight: 500;
    color: #000; 
    padding: 10px 15px;
    flex: 1 1;
    line-height: normal;
}

.BBPRViewModel .BBPRVMInfo {
    flex: 1 1;
    overflow: auto;
}

.BBPRViewModel .BBPRVMInfo .BBPVCMDIHead {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    padding-bottom: 0;
}

.BBPRViewModel .BBPRVMInfo .BBPVCMDIHead .BBPVCMDIHIcon {
    display: flex;
}

.BBPRViewModel .BBPRVMInfo .BBPVCMDIHead .BBPVCMDIHIcon img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    object-position: center;
}

.BBPRViewModel .BBPRVMInfo .BBPVCMDIHead .BBPVCMDIHTitle {
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRVMIRCol {
    padding: 5px 15px;
    width: 50%;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRVMIRCol.BBPVUMDIRCFull {
    width: 100%;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRVMIRCol .BBPRVMIRCLabel {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    line-height: normal;
    margin-bottom: 5px;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRVMIRCol .BBPVUMDIRCTitle {
    font-size: 14px;
    color: #000;
    line-height: normal;
    text-transform: capitalize;
}

.BBPRViewModel .BBPVUMDBtn {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #ddd;
} 

/*  */

.BBPBViewReceipt {
    padding: 20px;
    padding-bottom: 10px; 
}

.BBPBViewReceipt .BBPBVRHead {
    display: flex; 
    margin-bottom: 20px;
}

.BBPBViewReceipt .BBPBVRHead .BBPBVRHLogo {
    flex: 1 1;
    display: flex;
}
.BBPBViewReceipt .BBPBVRHead .BBPBVRHLogo img {
    width: 200px;
    height: auto;
}
.BBPBViewReceipt .BBPBVRHead .BBPBVRHText {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    line-height: normal;
}

.BBPBViewReceipt .BBPBVRRow {
    display: flex;
    flex-wrap: wrap;
}

.BBPBViewReceipt .BBPBVRRow .BBPBVRRCol {
    width: 50%;
}

.BBPBViewReceipt .BBPBVRGTitle {
    font-size: 14px;
    font-weight: 700;
    color: #175783;
    line-height: normal;
    margin-bottom: 3px;
}

.BBPBViewReceipt .BBPBVRTitle {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    line-height: normal;
    margin-bottom: 3px;
}

.BBPBViewReceipt .BBPBVROp {
    font-size: 13px;
    line-height: normal;
    color: #666;
    margin-bottom: 3px;
}

.BBPBViewReceipt .BBPBVROT {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin-bottom: 3px;
    line-height: 22px;
}

.BBPBViewReceipt .BBPBVROT span {
    display: block;
    font-weight: 500;
}

.BBPBViewReceipt .BBPBVRMail {
    display: flex;
}

.BBPBViewReceipt .BBPBVRMail a {
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    color: #175783;
}

.BBPBViewReceipt .BBPBVRGTitle span {
    color: #000;
    font-weight: 500;
}

.BBPBViewReceipt .BBPBVRRow .BBPBVRRCol.BBPBVRRCRight {
    text-align: right;
}

.BBPBViewReceipt .BBPBVMTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.BBPBViewReceipt .BBPBVRSpace {
    padding: 3px 0;
}

.BBPBViewReceipt .BBPBVRTable {
    padding: 15px 0;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTHead {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid #989898;
}
 
.BBPBViewReceipt .BBPBVRTable .BBPBVRTCol {
    width: 20%;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTCol:last-child {
    text-align: right;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTHead .BBPBVRTCol {
    font-weight: 700;
    color: #175783;
    font-size: 14px;
    padding: 7px;
    line-height: normal;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTHI {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e3e3e3;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTHI .BBPBVRTCol {
    line-height: normal;
    font-size: 13px;
    font-weight: 500;
    color: #454343;
    padding: 5px 7px;
    text-transform: capitalize;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTHI .BBPBVRTCH {
    font-weight: 700;
    color: #000;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTFoot {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid #ddd;
    background-color: #ddd;
}

.BBPBViewReceipt .BBPBVRTable .BBPBVRTFoot .BBPBVRTCol {
    line-height: normal;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    padding: 5px 7px;
}

.BBPBViewReceipt .BBPBVRTC {
    padding-bottom: 5px;
}

.BBPBViewReceipt .BBPBVRTC .BBPBVRTCHead {
    font-size: 14px;
    font-weight: 700;
    color: #175783;
    line-height: normal;
    margin-bottom: 3px;
}

.BBPBViewReceipt .BBPBVRTC .BBPBVRTCItem {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin-bottom: 3px;
    line-height: 18px;
    padding-left: 15px;
}

.BBPBViewReceipt .BBPBVRGSTHead {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.BBPBViewReceipt .BBPBVRGLine {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.BBPBViewReceipt .BBPBVRGLink {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    line-height: normal;
}

.BBPBViewReceipt .BBPBVRGLink a {
    font-size: 13px;
    line-height: normal;
    font-weight: 500;
    color: #175783;
}

.BBPBViewReceipt + .BBPBVRBtn {
    display: flex;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRVMIRTitle {
    padding: 5px 15px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    line-height: normal;
    margin-bottom: 5px;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRPrt {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #f4f7fc;
    border-top: 1px solid #d6d3d3;
} 

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRPrt .BBPRPInfo {
    width: 70%;
    padding: 10px 15px;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRPrt .BBPRPImg {
    width: 30%;
    padding: 10px 15px;
    display: flex;
}

.BBPRViewModel .BBPRVMInfo .BBPRVMIRow .BBPRPrt .BBPRPImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.BBPRPrt .BBPRPInfo .BBPRPISku {
    font-size: 13px;
    font-weight: 500;
    color: #175783;
    line-height: normal;
}

.BBPRPrt .BBPRPInfo .BBPRPIName {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.BBPRPrt .BBPRPInfo .BBPRPIQt,
.BBPRPrt .BBPRPInfo .BBPRPIPri,
.BBPRPrt .BBPRPInfo .BBPRPIAmt,
.BBPRPrt .BBPRPInfo .BBPRPIMt {
    line-height: 28px;
    font-size: 13px;
    color: #000;
    font-weight: 500;
    text-transform: capitalize;
}

.BBPRPrt .BBPRPInfo .BBPRPISku span,
.BBPRPrt .BBPRPInfo .BBPRPIQt span,
.BBPRPrt .BBPRPInfo .BBPRPIAmt span,
.BBPRPrt .BBPRPInfo .BBPRPIPri span,
.BBPRPrt .BBPRPInfo .BBPRPIMt span {
    font-weight: 600;
}

.BBPAlertDialog {
    padding: 30px;
}

.BBPAlertDialog .BBPADHead {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.BBPAlertDialog .BBPADHead .BBPSDAIcon {
    display: flex;
    font-size: 50px;
}
 
.BBPAlertDialog .BBPADHead .BBPSDATitle {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
    padding-left: 15px;
}

.BBPAlertDialog .BBPSDASTitle {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.09em;
    color: #2a2a2a;
}

.BBPAlertDialog .BBPADBtn {
    display: flex;
    justify-content: center; 
    padding-top: 20px;
}

.BBPAlertDialog .BBPADBtn button { 
    margin: 0 15px;
}
 
.BBPAlertDialog .BBPADBtn button.BBPADBDelete {
    background-color: #ee2505;
}
 
@media only screen and (max-width:767px) { 

    .BBPAlertDialog {
        padding: 15px;
    }

    .BBPAlertDialog .BBPADHead { 
        margin-bottom: 20px;
    }

    .BBPAlertDialog .BBPSDASTitle { 
        font-size: 16px; 
    }

    .BBPAlertDialog .BBPADHead .BBPSDAIcon {
        font-size: 40px;
    }

    .BBPAlertDialog .BBPADHead .BBPSDATitle { 
        font-size: 16px; 
        padding-left: 20px;
    }
 
}
.BBPCusTable {
    display: flex;
    flex-direction: column;
    height: 100%;
}
 
.BBPCusTable .BBPCTHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}  
 
.BBPCusTable .BBPCTBody {
    flex: 1 1;
    padding-top: 16px;
    overflow: auto;
}

.BBPEditCustomer {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPEditCustomer .BBPECHead {
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPEditCustomer .BBPECHead .BBPECHTab {
    flex: 1 1;
    display: flex;
}

.BBPEditCustomer .BBPECHead .BBPECHTab button {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0em;
    line-height: 24px;
    text-align: left;
    color: #000;
    text-transform: capitalize;
    padding: 15px 50px;
    position: relative;
    text-decoration: none;
    position: relative;
}

.BBPEditCustomer .BBPECHead .BBPECHTab button.BBPECHTAct::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px;
    background-color: #175783;
}

.BBPEditCustomer .BBPECBody {
    flex: 1 1;
    padding: 7.5px;
    overflow: auto;
}
 

.BBPEditCustomer .BBPVCDet .BBPVCDTitle { 
    line-height: normal;
    padding: 7.5px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.BBPEditCustomer .BBPVCDet .BBPVCDBtn {
    display: flex;
    justify-content: center;
    grid-column-gap: 15px;
    column-gap: 15px;
    padding: 7.5px;
}

.BBPEditCustomer .BBPVCDet .BBPVCDForm .BBPForm form.rjsf .field-object > fieldset .form-group {
    width: 33.33%; 
}

.BBPViewCustomer {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPViewCustomer .BBPVCHead {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
}

.BBPViewCustomer .BBPVCHead .BBPVCHTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 10px 15px;
    flex: 1 1;
    line-height: normal;
}

.BBPViewCustomer .BBPVCBody {
    flex: 1 1;
    overflow: auto;
    padding: 7.5px;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem {
    border-bottom: 1px solid #b8b8b8;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem:last-child {
    border-bottom: 0;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem .BBPVCBITitle {
    line-height: normal;
    padding: 7.5px;
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem .BBPVCBIDet {
    display: flex;
    flex-wrap: wrap;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem .BBPVCBIDet .BBPVCBIDCol {
    width: 33.33%;
    padding: 7.5px;
    padding-top: 0;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem .BBPVCBIDet .BBPVCBIDCol .BBPVCBIDCTitle {
    font-size: 13px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    line-height: normal;
    margin-bottom: 5px;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem .BBPVCBIDet .BBPVCBIDCol .BBPVCBIDCSTitle {
    font-size: 14px;
    color: #000;
    line-height: normal;
    text-transform: capitalize;
}

.BBPViewCustomer .BBPVCBody .BBPVCBItem .BBPVCBIDet .BBPVCBIDCol .BBPVCBIDCImg img {
    display: block;
    width: 150px;
    height: auto;
}

.BBPEditCustomer .BBPVCDet .BBPVCDForm .BBPVCDFSk {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 15px;
    column-gap: 15px;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.BBPEditCustomer .BBPVCDet .BBPVCDForm .BBPVCDFSk > div {
    width: calc(33.33% - 10px);
}

.BBPEditCustomer .BBPVCDet .BBPVCDMsg {
    color: #2e7d32;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 7.5px 0;
}
 
.BBPEditCustomer .BBPVCDet .BBPVCDTable {
    padding-top: 15px;
    height: 600px;
}

.BBPEditCustomer .BBPVCDet .BBPVCDErrMsg {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #ee2505;
}
.BBPSelectOption .SelectOptionLabel {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    background-color: #175783;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 7px 15px;
    padding-right: 25px;
    text-decoration: none;
    border: 2px solid #175783;
    min-width: 125px;
    justify-content: space-between;
}

.BBPSelectOption .SelectOptionLabel::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 10px;
    right: 10px;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(-45deg);
}
  
.BBPSelectOption .SelectOptionLabel:hover { 
    background-color: #175783;
} 
  
.BBPSOptions {
    z-index: 99;
}

.BBPSOptions .BBPSOList li {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.BBPSOptions .BBPSOList li.BBPSPSelected {
    background-color: rgb(0 0 0 / 6%);
}

.BBPTextField input {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: 28px;
    text-align: left;
    color: #171725;
}

.BBPImg {
    display: flex;
}

label.BBPLabel {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #171725;
    display: block;
    margin-bottom: 10px;
}
 
label.BBPLabel span {
    color: #ee2505;
}

.BBPImg .BBPIUpload .BBPIULabel {
    padding: 7.5px 25px;
    font-size: 14px;
    line-height: 21px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
    background-color: unset;
    box-shadow: unset;
}
 
.BBPImg .BBPIUpload .BBPIULabel span.MuiButton-endIcon svg {
    font-size: 30px;
}
 
.BBPImg .BBPIPreview {
    margin-left: 10px;
    width: 70px;
    height: 47px;
    border: 1px solid #ddd;
}

.BBPImg .BBPIPreview img { 
    width: 100%; 
    height: 100%; 
    object-fit: contain;
    object-position: center;
}

.BBPSError {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: #d32f2f;
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel.BBPAACIError,
.BBPImg .BBPIUpload .BBPIULabel.BBPIULError,
.BBPSelectOption .SelectOptionLabel.SOLError {
    border-color: #d32f2f;
}
 
.BBPAFDPopper .BBPAFDPaper ul li {
    text-transform: capitalize;
}
 
.BBPAAPopper .BBPAAPInner {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.BBPAAPopper .BBPAAPInner .BBPAAPILoader{
    padding: 8.5px 16px;
}

.BBPRFSlider {
    display: flex;
    padding: 0 15px;
}
 
.BBPApiAutocomplete .BBPAACInner .BBPAACILabel {
    padding: 13px 14px;
    padding-right: 40px;
    font-size: 14px;
    line-height: 19px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel:hover {
    border-color: rgba(0, 0, 0, 0.87);
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel.open {
    border-color: #1976d2;
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 15px;
    right: 15px;
    border-left: 2px solid #171725;
    border-bottom: 2px solid #171725;
    transform: rotate(-45deg);
} 

.BBPAAPSearch .BBPAAInput {
    display: flex;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

.BBPAAPSearch .BBPAAInput input {
    padding: 10px 10px;
    padding-right: 40px;
    font-size: 14px;
    line-height: 20px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
    width: 100%;
    border-radius: 4px;
    outline: 0;
}
 
.BBPAAPSearch .BBPAAILoader {
    padding: 8.5px 16px;
}

.BBPACFNew .BBPACFNMain .BBPACFNField input {
    font-size: 14px;
    padding: 4.5px;
    text-transform: capitalize;
}

.BBPACFNPopper .BBPACFNListbox {
    text-transform: capitalize;
}   

.BBPSuccessDialog {
    padding: 30px;
}

.BBPSuccessDialog .BBPSDHead {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.BBPSuccessDialog .BBPSDHead .BBPSDHIcon {
    display: flex;
}

.BBPSuccessDialog .BBPSDHead .BBPSDHIcon svg {
    font-size: 70px;
}

.BBPSuccessDialog .BBPSDHead .BBPSDHTitle {
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
    padding-left: 24px;
}

.BBPSuccessDialog .BBPSDHSTitle {
    text-align: center;
    font-weight: normal;
    font-size: 22px;
    letter-spacing: 0.09em;
    color: #2a2a2a;
}

.BBPSuccessDialog .BBPSDBtn {
    display: flex;
    justify-content: center; 
    padding-top: 30px;
}

.BBPSuccessDialog .BBPSDBtn button {
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    background: #175783;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 60px;
    margin: 0 15px;
    min-width: 180px;
}


 
@media only screen and (max-width:767px) { 

    .BBPSuccessDialog {
        padding: 15px;
    }

    .BBPSuccessDialog .BBPSDHead { 
        margin-bottom: 20px;
    }

    .BBPSuccessDialog .BBPSDHSTitle { 
        font-size: 16px; 
    }

    .BBPSuccessDialog .BBPSDHead .BBPSDHIcon svg {
        font-size: 40px;
    }

    .BBPSuccessDialog .BBPSDHead .BBPSDHTitle { 
        font-size: 16px; 
        padding-left: 20px;
    }
 
}
.BBPUser {
    display: flex;
    flex-direction: column;
    height: 100%;
}
 
.BBPUser .BBPUHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} 

.BBPUser .BBPUHead .BBPUHBtn {
    display: flex;
    flex-wrap: wrap;
    padding-left: 24px;
  }

.BBPUser .BBPUHead .BBPUHBtn a.BBPTableBtn,
.BBPUser .BBPUHead .BBPUHBtn button.BBPTableBtn {
    margin-right: 24px;
}

.BBPUser .BBPUBody {
    flex: 1 1;
    padding-top: 16px;
    overflow: auto;
}

.BBPUserAdd {
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPUserAdd .BBPUAHead {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}
 
.BBPUserAdd .BBPUAHead .BBPUAHTitle {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    letter-spacing: .5px;
}

.BBPUserAdd .BBPUABody {
    height: 100%;
    overflow: auto;
}

.BBPUserAdd .BBPUABody .BBPUABPannel {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPUserAdd .BBPUABody .BBPUABPannel .BBPUABPInner {
    flex: 1 1;
    overflow: auto;
    padding: 10px 0px;
}

.BBPUABPannel .BBPUABPInner .BBPUABPIFields {
    padding: 0 10px; 
    display: flex;
    flex-wrap: wrap;
}
  
.BBPUABPannel .BBPUABPInner .BBPUABPIFields .BBPCABPIFField {
    width: calc(25% - 20px);
    margin: 10px;
}

.BBPUABPIFields .BBPCABPIFField .BBPBABPIFLabel {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #171725;
    display: block;
    margin-bottom: 10px;
}

.BBPUABPIFields .BBPCABPIFField .BBPBABPIFLabel span {
    color: #ee2505;
}

.BBPUserAdd .BBPUABody .BBPUABPannel .BBPUABPBtns {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.BBPUserAdd .BBPUABody .BBPUABPannel .BBPUABPBtns button {
    margin: 0 15px;
}

.BBPUserAdd .BBPUABody .BBPUABPannel .BBPUABPErr {
    text-align: center;
    font-size: 18px;
    color: #d32f2f;
    font-weight: 600;
}
 
.BBPUABPIFields .BBPCABPIFField .BBPBABPIFImg {
    padding: 12px 25px;
    font-size: 16px;
    line-height: 21px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
    background-color: unset;
    box-shadow: unset;
}

.BBPUABPIFields .BBPCABPIFField .BBPBABPIFImg span.MuiButton-endIcon svg {
    font-size: 30px;
}

.BBPUABPannel .BBPUABPInner .BBPUABPIFields .BBPCABPIFField.BBPBABPIFFU {
    width: auto;
}

.BBPUABPannel .BBPUABPInner .BBPUABPIFields .BBPBABPIFFUImg {
    margin: 10px;
    width: 100px;
    height: 87px;
    border: 1px solid #ddd;
}

.BBPUABPannel .BBPUABPInner .BBPUABPIFields .BBPBABPIFFUImg img { 
    width: 100%; 
    height: 100%; 
    object-fit: contain;
    object-position: center;
}

.BBPVUMDialog {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPVUMDialog .BBPVUMDHead {
    border-bottom: 1px solid #ddd; 
    display: flex;
    flex-wrap: wrap;
}

.BBPVUMDialog .BBPVUMDHead .BBPVUMDHTitle { 
    font-size: 16px;
    font-weight: 500;
    color: #000; 
    padding: 10px 15px;
    flex: 1 1;
    line-height: normal;
}

.BBPVUMDialog .BBPVUMDInfo {
    flex: 1 1;
    overflow: auto;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVCMDIHead {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    padding-bottom: 0;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVCMDIHead .BBPVCMDIHIcon {
    display: flex;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVCMDIHead .BBPVCMDIHIcon img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    object-position: center;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVCMDIHead .BBPVCMDIHTitle {
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol {
    padding: 5px 15px;
    width: 50%;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol.BBPVUMDIRCFull {
    width: 100%;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol .BBPVUMDIRCLabel {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    line-height: normal;
    margin-bottom: 5px;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol .BBPVUMDIRCTitle {
    font-size: 14px;
    color: #000;
    line-height: normal;
    text-transform: capitalize;
}

.BBPVUMDialog .BBPVUMDBtn {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
} 

.BBPUABPannel .BBPUABPInner .BBPUABPIFields.BBPBABPIRF  .BBPCABPIFField {
    width: calc(22% - 13px);
}

.BBPBABPIRF .BBPBABPIFAddRemove {
    display: flex;
    align-items: center;
    margin: 10px;
    padding-top: 30px;
}
 
.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol .BBPVUMDIRCTitle .BBPVUMDIRCTText {
    margin-top: 5px;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer {
    width: 100%;
}
 

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer > fieldset > legend {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-bottom: 15px;
    display: block;
    width: 100%;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer > fieldset .field-array {
    display: flex;
    align-items: center;
    margin: 7px 0;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer > fieldset .field-array label.control-label {
    margin-bottom: 0;
    line-height: normal;
    font-weight: 500;
    color: #000;
    min-width: 125px;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer > fieldset .field-array .checkboxes {
    display: flex;
    align-items: center;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer > fieldset .field-array .checkboxes .checkbox {
    margin-right: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #175783;
    line-height: normal;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer > fieldset .field-array .checkboxes .checkbox input {
    margin: 0;
    margin-right: 10px;
    width: 15px;
    height: 15px;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUserPer > fieldset .field-array .checkboxes .checkbox label > span {
    display: flex;
    align-items: center;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol.BBPVUMDIRCTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #000;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin: 7px 0;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol .BBPVUMDIRCCheck {
    display: flex;
    flex-wrap: wrap;
}

.BBPVUMDialog .BBPVUMDInfo .BBPVUMDIRow .BBPVUMDIRCol .BBPVUMDIRCCheck .BBPVUMDIRCCB {
    line-height: normal;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 4px;
    background-color: #175783;
    color: #fff;
    margin-right: 5px;
}
 
.BBPChangePassword {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPChangePassword .BBPCPHead {
    border-bottom: 1px solid #ddd; 
    display: flex;
    flex-wrap: wrap;
}

.BBPChangePassword .BBPCPHead .BBPCPHTitle { 
    font-size: 16px;
    font-weight: 500;
    color: #000; 
    padding: 10px 15px;
    flex: 1 1;
    line-height: normal;
}
 
.BBPChangePassword .BBPCPBtn {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
} 
 
.BBPChangePassword .BBPCPBtn button {
    margin: 0 10px; 
} 

.BBPChangePassword .BBPCPInfo {
    padding: 7.5px;
    border-bottom: 1px solid #ddd;
}

.BBPChangePassword .BBPCPInfo .BBPForm form.rjsf .field-object > fieldset .form-group {
    width: 100%;
}

.BBPChangePassword .BBPCPInfo .BBPForm {
    max-width: 375px;
    margin: 0 auto;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUpiLg {
    width: 100%;
    padding: 7.5px 0;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPUpiLg > fieldset > legend {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-left: 7.5px;
    display: block;
    width: 100%;
}
.BBPSettingsPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPSettingsPage .BBPSPTab {
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPSettingsPage .BBPSPTab a.BBPSPTBtn {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0em;
    line-height: 24px;
    text-align: left;
    color: #000;
    text-transform: capitalize;
    padding: 15px 50px;
    position: relative;
    text-decoration: none;
}

.BBPSettingsPage .BBPSPTab a.BBPSPTBtn.BBPSPTBActive::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px;
    background-color: #175783;
}

.BBPSettingsPage .BBPSPTabView {
    flex: 1 1;
    overflow: auto;
}

.BBPSettingsPage .BBPSPTabView .BBPSPTView { 
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPSettingsPage .BBPSPTabView .BBPSPTView .BBPSPTVForm {
    flex: 1 1;
    overflow: auto;
    padding: 7.5px;
}

.BBPSettingsPage .BBPSPTabView .BBPSPTView .BBPSPTVBtn {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.BBPSettingsPage .BBPSPTabView .BBPSPTView  .BBPForm form.rjsf .field-object > fieldset .form-group {
    width: 33.33%; 
}

.BBPSettingsPage .BBPSPTabView .BBPSPTView .BBPSPTVForm .BBPSPTVFFLoad {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 15px;
    column-gap: 15px;
    grid-row-gap: 15px;
    row-gap: 15px;
    padding: 7.5px;
}

.BBPSettingsPage .BBPSPTabView .BBPSPTView .BBPSPTVForm .BBPSPTVFFLoad .BBPSPTVFFLInner {
    width: calc(33.33% - 10px);
}
 
.BBPSettingsPage .BBPSPTabView .BBPSPTView .BBPSPTVErr {
    text-align: center;
    font-size: 18px;
    color: #d32f2f;
    font-weight: 600;
}
html,
body,
img,
fieldset,
abbr,
acronym {
	border: 0;
	max-width: 100%;
	width: auto;
}
 
body {
  font-size: 16px;
  color: #000;
  line-height: 32px; 
  margin: 0;
  background-color: #F4F7FC;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
}

html, body, .BBPRoot {
  height: 100%;
}

*, ::after, ::before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Scroll Bar */

* {
  scrollbar-width: thin;
  scrollbar-color: #00000033 #00000018;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s;
  overflow: auto;
  margin: auto;
}

*::-webkit-scrollbar-track {
  border-radius: 50px;
  overflow: auto;
  overflow-y: scroll;
  overflow-x: scroll;
  background: #c4c5c8;
}

*::-webkit-scrollbar-thumb {
  background: #0000;
  border-radius: 50px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #00000050;
  transition-duration: 10s;
}
 
.BBPApp {
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
}
 
.BBPApp .BBPAInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

a.BBPTableBtn, 
button.BBPTableBtn {
  background-color: #fff;
  border-radius: 4px;
  color: #5A607F;
  font-family: Poppins;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  box-shadow: unset;
  line-height: normal;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
a.BBPTableBtn span {
  font-size: 16px;
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}
a.BBPTableBtn span svg,
button.BBPTableBtn span svg.MuiSvgIcon-root {
  font-size: 16px;
}

a.BBPTableBtn:hover,
button.BBPTableBtn:hover {
  background-color: #175783;
  color: #fff;
}

button.BBPButton {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: normal;
  text-align: left;
  color: #fff;
  border-radius: 10px;
  background-color: #175783;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 35px;
  text-decoration: none;
  border: 2px solid #175783;
}

button.BBPButton[disabled] {
  color: #fff;
  opacity: .5;
}

button.BBPButton:hover {
  background-color: #175783;
  border: 2px solid #175783;
}
 
button.BBPButton.BBPBRed {
  background-color: #ee2505;
  border: 2px solid #ee2505;
}
 
button.BBPButton.BBPOButton {
  background: unset;
  color: #175783;
}

.BBPApp.BBPLP {
  padding: 0;
}

.BBPApp.BBPLP .BBPBodyView {
  padding: 0;
}

button.BBPButton .MuiLoadingButton-loadingIndicator {
  color: #fff;
}

/* Forms */

.BBPForm {
  width: 100%;
}

.BBPForm form.rjsf {
  display: block;
  position: relative;
}

.BBPForm form.rjsf button.BBPFBtn {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  padding: 0;
  border: 0;
  opacity: 0;
  z-index: -9;
}

.BBPForm form.rjsf .field-object > fieldset {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.BBPForm form.rjsf .field-object > fieldset .form-group {
  width: 25%;
  padding: 7.5px;
}
  
.BBPForm form.rjsf .field-object > fieldset .form-group label.control-label {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: normal;
  text-align: left;
  color: #171725;
  display: block;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.BBPForm form.rjsf .field-object > fieldset .form-group label.control-label span.required {
  color: #ee2505;
  display: inline-block;
  margin-left: 5px;
}

.BBPForm form.rjsf .field-object > fieldset .form-group .form-control {
  padding: 12px 15px;
  font-size: 14px;
  line-height: 21px;
  text-transform: inherit;
  font-weight: 500;
  color: #171725;
  border: 1px solid rgba(0, 0, 0, 0.23);
  justify-content: flex-start;
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;
  outline: 0;
}

.BBPForm form.rjsf .field-object > fieldset .form-group textarea.form-control {
  resize: unset;
}

.BBPForm form.rjsf .field-object > fieldset .form-group select.form-control {
  -webkit-appearance: none;
          appearance: none;
  padding-right: 40px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='none' d='M0 0h24v24H0z'/><path d='M12 16l-6-6h12z' fill='rgb(23 23 37)'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 7px) center;
  background-size: 24px;
  cursor: pointer;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array {
  width: 100%;
  padding: 0;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .field-array-of-object {
  display: block;
  padding: 0;
  margin: 0;
  position: relative;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .field-array-of-object > legend {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding: 0 7.5px;
  padding-top: 7.5px;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row {
  display: flex;
  flex-wrap: wrap;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row > .array-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row > .array-item .col-xs-9 {
  flex: 1 1;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row > .array-item .col-xs-9 .field-object {
  width: 100%;
  padding: 0;
}
  
.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row > .array-item .array-item-toolbox {
  padding: 7.5px;
  padding-top: 42px;
  display: flex;
  align-items: flex-start;
  width: 75px;
  justify-content: flex-end; 
}
  

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row > .array-item .array-item-toolbox .btn-group button {
  border: 0;
  padding: 7px !important;
  display: flex;
  cursor: pointer;
  font-size: 24px;
  color: #818181;
  background-color: unset;
} 
.BBPForm form.rjsf .field-object fieldset .form-group.field-array .field-array-of-object .array-item-list:empty + button.array-item-add {
    bottom: 0;
} 

.BBPForm form.rjsf .field-object > fieldset .form-group ul.error-detail {
  margin: 0;
  padding-left: 20px;
}
  
.BBPForm form.rjsf .field-object > fieldset .form-group ul.error-detail li.text-danger {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  text-transform: capitalize;
}

.BBPForm form.rjsf .field-object > fieldset .form-group.field-error .form-control {
  border-color: #d32f2f;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .BBPFAB {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .BBPFAB .array-item-add {
  border-color: #175783;
  color: #175783;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 8px;
  align-items: center;
  line-height: normal;
  padding: 7px 15px;
}

.BBPTPopper .BBPTooltip {
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}

.BBPPwaAuth {
  height: 100%;
  padding: 16px;
}
  
.BBPPwaAuth .BBPPAInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPForm form.rjsf .field-object > fieldset .form-group.BBPFFWidth {
  width: 100%;
}
